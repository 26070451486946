<template>
  <v-form
    ref="searchForm"
    action="/search"
  >
    <h2 class="mb-5">
      {{ $t("S'inscrire à notre Newletter") }}
    </h2>
    <p class="footer-text mb-5">
      {{ $t("Soyez informé(e) des Actions Promotionnelles à venir") }}
      <v-text-field
        v-model="message.username"
        class="mt-2"
        outlined
        dense
        :label="$t('Nom . . .')"
        :placeholder="$t('Nom . . .')"
      />
      <v-text-field
        v-model="message.email"
        type="email"
        class=""
        dense
        outlined
        :label="$t('Entrer votre Email . . .')"
        :placeholder="$t('Entrer votre Email . . .')"
      />
      <v-btn
        :loading="sending"
        block
        color="primary"
        depressed
        @click="send"
      >
        {{ $t("Envoyer") }}
      </v-btn>
    </p>
    <v-snackbar
      v-model="snackbar.active"
      dark
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn

        text
        @click="snackbar.active = false"
      >
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </v-form>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Newsletter',
    components: {},
    props: {
      name: {
        type: String,
        default: 'newsletter',
      },
    },
    data: () => ({
      sending: false,
      loading: false,
      loading_article: true,
      error: null,
      message: {},
      stats: [],
      recaptcha_token: '',
      confirmObject: { action: '', text: '', active: false },
      snackbar: { active: false, text: '', color: 'primary' },
    }),
    computed: {
      ...mapState({
        user: 'user',
      }),
    },
    created: function () {
      if (this.$store.getters.isLoggedIn && this.user) {
        this.message.username = this.user.name || ''
        this.message.enail = this.user.email || ''
      }

      // Hide reCAPTCHA badge:
      // recaptcha.hideBadge();

      // Show reCAPTCHA badge:
      try {
        this.recaptcha()
        const recaptcha = this.$recaptchaInstance

        // recaptcha.showBadge()
      } catch (e) {
      }
    },
    destroyed: function () {
      this.recaptcha()
    },
    methods: {

      async recaptcha () {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        // console.log("recaptcha ", token);
        this.recaptcha_token = await this.$recaptcha('login')
        // Do stuff with the received token.
        const recaptcha = this.$recaptchaInstance

        // Hide reCAPTCHA badge:
        // recaptcha.hideBadge()

        // Show reCAPTCHA badge:
        // recaptcha.showBadge()
      },

      send () {
        if (!this.message.email || this.message.email.trim().length < 3 || !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.message.email))) {
          this.toast(this.$t('Please enter a valid email'), 'red')
          return false
        }
        if (!this.message.username || this.message.username.trim().length < 3) {
          this.toast(this.$t('Please enter a valid name'), 'red')
          return false
        }

        this.message.token = this.recaptcha_token
        this.message.name = this.name || null

        this.sending = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/subscribe',
          data: this.message,
          validateStatus: (status) => {
            return true
          },
        }).catch(error => {
          this.sending = false
          this.toast('Error', 'red')
          console.log(error)
        }).then(async response => {
          this.sending = false
          if (response.data.error) {
            this.toast(response.data.message, 'red')
            return false
          }
          this.toast(this.$t('Your subscription was successful.'), 'success')

          this.message = {}

          // this is now called!
        }).then(() => {
          this.sending = false
        })
      },
      toast (message, color) {
        this.$store.commit('setValue', {
          type: 'display_notification',
          value: { message: message, color: color },
        })
      },
    },
  }
</script>

<style scoped>

</style>
